import React from 'react'
import { APP_NAME } from 'configs/AppConfig';
import { Button } from "antd";

import { Link } from "react-router-dom";
export default function Footer({isMobile}) {
	const Mailto = ({ email, subject, body, ...props }) => {
		return (
			<Button type="primary">
			<a className="text-gray"  href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
					<span style={{ color: 'white' }}>{props.children}</span>
				</a>
			</Button>
		);
	}

	return (
		<footer className="footer">
			<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved.</span>
			<a className="text-gray" target={'_blank'} href="https://www.speakho.co/privacy-policy" rel="noreferrer">Privacy Policy</a>
			{isMobile ?
				<div style={{paddingTop:'20px', paddingBottom:'20px'}}>
					{/* <a className="text-gray" href="/#" onClick={e => e.preventDefault()}>Term & Conditions</a> */}
					{/* <span className="mx-2 text-muted"> | </span> */}
					{/* <a className="text-gray" target={'_blank'}  href="https://www.pmsys.co/privacy-policy" rel="noreferrer">Give Feedback</a> */}

					<Mailto email="contactus@speakho.co" subject="Feedback" body="Please share your detailed feedback">
						Give Feedback
					</Mailto>

				</div> :
				<div>
					{/* <a className="text-gray" href="/#" onClick={e => e.preventDefault()}>Term & Conditions</a> */}
					{/* <span className="mx-2 text-muted"> | </span> */}
					{/* <a className="text-gray" target={'_blank'}  href="https://www.pmsys.co/privacy-policy" rel="noreferrer">Give Feedback</a> */}

					<Mailto email="contactus@speakho.co" subject="Feedback" body="Please share your detailed feedback">
						Give Feedback
					</Mailto>

				</div>
		}
			
		</footer>
	)
}

