import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import {
  EXPENSE_ADD,
  EXPENSE_DELETE,
  EXPENSE_LIST,
  EXPENSE_SEARCH,
  QUOTE_LIST,
  QUOTE_DELETE,
  QUOTE_SEARCH,
  QUOTE_ADD,
  QUOTE_DETAILS,
  QUOTE_UPDATE_STATUS,
  JOURNAL_ADD,
  JOURNAL_LIST,
  JOURNAL_DETAILS,
  JOURNAL_DELETE,
  JOURNAL_UPDATE_STATUS,
  INVOICE_ADD,
  INVOICE_LIST,
  INVOICE_DETAILS,
  INVOICE_UPDATE_STATUS,
  INVOICE_PAYMENT,
  PURCHASE_ADD,
  PURCHASE_LIST,
  PURCHASE_DETAILS,
  PURCHASE_UPDATE_STATUS,
  PURCHASE_PAYMENT,
  PURCHASE_DELETE,
  BILL_ADD,
  BILL_DELETE,
  BILL_LIST,
  BILL_DETAILS,
  BILL_UPDATE_STATUS,
  BILL_PAYMENT,
  BILL_UPDATE_STATUS_SUCCESS,
} from "../constants/App";

import {
  expenseListSuccess,
  quoteListSuccess,
  quoteDetailsSuccess,
  quoteAddSuccess,
  journalAddSuccess,
  journalListSuccess,
  journalDetailsSuccess,
  invoiceAddSuccess,
  invoiceListSuccess,
  invoiceDetailsSuccess,
  billAddSuccess,
  billListSuccess,
  billDetailsSuccess,
  purchaseAddSuccess,
  purchaseListSuccess,
  purchaseDetailsSuccess,
  markLoading,
} from "../actions/Business";

import { push } from "react-router-redux";
import ApiService from "services/ApiService";
import axios from "axios";
import { showAuthMessage } from "redux/actions/Auth";

export function* expense() {
  yield takeEvery(EXPENSE_LIST, function* ({ payload }) {
    try {
      console.log("in expense accounting", payload);
      const reportData = yield call(ApiService.getExpenseList, {
        page: 1,
        searchText: "",
      });
      console.log("in sucess", reportData);
      if (reportData) {
        yield put(expenseListSuccess(reportData));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* deleteExpense() {
  yield takeEvery(EXPENSE_DELETE, function* ({ payload }) {
    console.log("in tax search payload", payload);
    try {
      const reportData = yield call(ApiService.deleteExpenseList, payload);
      if (reportData) {
        // const reportTaxData = yield call(ApiService.getTaxList, payload);
        //yield put(taxListSuccess(reportData));
        const reportData1 = yield call(ApiService.getExpenseList, {
          page: 1,
          searchText: "",
        });
        if (reportData1) {
          yield put(expenseListSuccess(reportData1));
        } else {
          yield put(showAuthMessage("Something went wrong"));
        }
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* searchExpense() {
  yield takeEvery(EXPENSE_SEARCH, function* ({ payload }) {
    console.log("in tax search payload", payload);
    try {
      const reportData = yield call(ApiService.searchExpenseList, payload);
      if (reportData) {
        yield put(expenseListSuccess(reportData));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* addExpense() {
  yield takeEvery(EXPENSE_ADD, function* ({ payload }) {
    console.log("in expense search payload", payload);
    try {
      const reportData = yield call(ApiService.addExpenseList, payload);
      console.log("reportData", reportData);
      if (reportData) {
        const reportData1 = yield call(ApiService.getExpenseList, {
          page: 1,
          searchText: "",
          startDate: "",
          endDate: "",
        });
        if (reportData1) {
          yield put(expenseListSuccess(reportData1));
        } else {
          yield put(showAuthMessage("Something went wrong"));
        }
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* quote() {
  yield takeEvery(QUOTE_LIST, function* ({ payload }) {
    console.log("in payload", payload);
    try {
      const reportData = yield call(ApiService.getQuoteList, payload);
      console.log("reportData", reportData);
      if (reportData) {
        yield put(quoteListSuccess(reportData));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* deletequote() {
  yield takeEvery(QUOTE_DELETE, function* ({ payload }) {
    const { history } = payload;
    console.log("in contact delete payload", payload);
    try {
      const reportData = yield call(ApiService.deleteQuoteList, payload);
      console.log("reportData", reportData);

      if (reportData) {
        history.push("/app/business/quote/list");
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* searchquote() {
  yield takeEvery(QUOTE_SEARCH, function* ({ payload }) {
    console.log("in contact search payload", payload);
    try {
      const reportData = yield call(ApiService.searchContactList, payload);
      console.log("reportData", reportData);
      if (reportData) {
        yield put(quoteListSuccess(reportData));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* addquote() {
  yield takeEvery(QUOTE_ADD, function* ({ payload }) {
    console.log("in contact search payload", payload);
    try {
      const reportData = yield call(ApiService.addQuoteList, payload);
      console.log("reportData", reportData);
      if (reportData) {
        yield put(quoteAddSuccess(reportData));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* quoteDetails() {
  yield takeEvery(QUOTE_DETAILS, function* ({ payload }) {
    console.log("in contact search payload", payload);
    try {
      const reportData = yield call(ApiService.getQuoteDetails, payload);
      console.log("reportData", reportData);
      if (reportData) {
        yield put(quoteDetailsSuccess(reportData.data));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* quoteStatusUpdate() {
  yield takeEvery(QUOTE_UPDATE_STATUS, function* ({ payload }) {
    console.log("in contact search payload", payload);
    try {
      const reportData = yield call(ApiService.updateQuoteStatus, payload);
      console.log("reportData", reportData);
      if (reportData) {
        const { id, status, type, history } = payload;
        // if status update saga comes from mark as invoice modal
        if (type != null) {
          markLoading(false);
          // history.push(`/app/business/invoice/list`);
          history.push(`/app/business/invoice/edit?id=${id}&cloneType=QUOTE`);
          // window.location.reload()
        } else {
          if (status == "DRAFT") {
            history.push(`/app/business/quote/edit?id=${id}`);
          } else if (status == "DELETED") {
            history.push(`/app/business/quote/list`);
          } else {
            history.push(`/app/business/quote/view?id=${id}`);
          }
          // window.location.reload()
        }
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* addJounral() {
  yield takeEvery(JOURNAL_ADD, function* ({ payload }) {
    console.log("in contact journal payload", payload);
    try {
      const reportData = yield call(ApiService.addJournalList, payload);
      console.log("reportData", reportData);
      if (reportData) {
        yield put(journalAddSuccess(reportData));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* journal() {
  yield takeEvery(JOURNAL_LIST, function* ({ payload }) {
    console.log("in payload", payload);
    try {
      const reportData = yield call(ApiService.getJournalList, payload);
      if (reportData) {
        yield put(journalListSuccess(reportData));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* journalDetails() {
  yield takeEvery(JOURNAL_DETAILS, function* ({ payload }) {
    console.log("in journal detail payload", payload);
    try {
      const reportData = yield call(ApiService.getJournalDetails, payload);
      console.log("reportData", reportData);
      if (reportData) {
        yield put(journalDetailsSuccess(reportData.data));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* deleteJournal() {
  yield takeEvery(JOURNAL_DELETE, function* ({ payload }) {
    const { history } = payload;
    console.log("in contact delete payload", payload);
    try {
      const reportData = yield call(ApiService.deleteJournalList, payload);
      console.log("reportData", reportData);

      if (reportData) {
        history.push("/app/accounting/reports/journal/list");
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* addInvoice() {
  yield takeEvery(INVOICE_ADD, function* ({ payload }) {
    console.log("in invoice payload", payload);
    try {
      const reportData = yield call(ApiService.addInvoiceList, payload);
      console.log("reportData", reportData);
      if (reportData) {
        yield put(invoiceAddSuccess(reportData));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* invoice() {
  yield takeEvery(INVOICE_LIST, function* ({ payload }) {
    console.log("in payload", payload);
    try {
      const reportData = yield call(ApiService.getInvoiceList, payload);
      if (reportData) {
        yield put(invoiceListSuccess(reportData));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* invoiceDetails() {
  yield takeEvery(INVOICE_DETAILS, function* ({ payload }) {
    console.log("in journal detail payload", payload);
    try {
      const reportData = yield call(ApiService.getInvoiceDetails, payload);
      console.log("reportData", reportData);
      if (reportData) {
        yield put(invoiceDetailsSuccess(reportData.data));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* invoiceStatusUpdate() {
  yield takeEvery(INVOICE_UPDATE_STATUS, function* ({ payload }) {
    console.log("in journal status payload", payload);
    try {
      const reportData = yield call(ApiService.updateInvoiceStatus, payload);
      console.log("reportData", reportData);
      if (reportData) {
        const { id, invoiceStatus, history } = payload;

        if (invoiceStatus == "DRAFT") {
          history.push(`/app/business/invoice/edit?id=${id}`);
        } else {
          history.push(`/app/business/invoice/view?id=${id}`);
        }
        window.location.reload();
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* invoicePaymentSaga() {
  yield takeEvery(INVOICE_PAYMENT, function* ({ payload }) {
    console.log("in journal status payload", payload);
    try {
      const reportData = yield call(ApiService.makeInvoicePayment, payload);
      console.log("reportData", reportData);
      if (reportData) {
        const { history } = payload;
        console.log("reportData", reportData);
        if (reportData.success) {
          history.push(`/app/business/invoice/list`);
          window.location.reload();
        } else {
          yield put(showAuthMessage(reportData.message));
        }
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      console.log("error", err);
      yield put(showAuthMessage(err));
    }
  });
}

export function* addBill() {
  yield takeEvery(BILL_ADD, function* ({ payload }) {
    console.log("in invoice payload", payload);
    try {
      const reportData = yield call(ApiService.addBillList, payload);
      console.log("reportData", reportData);
      if (reportData) {
        yield put(billAddSuccess(reportData));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}
export function* bill() {
  yield takeEvery(BILL_LIST, function* ({ payload }) {
    console.log("in payload", payload);
    try {
      const reportData = yield call(ApiService.getBillList, payload);
      if (reportData) {
        yield put(billListSuccess(reportData));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}
export function* deleteBill() {
  yield takeEvery(BILL_DELETE, function* ({ payload }) {
    const { history } = payload;
    console.log("in contact delete payload", payload);
    try {
      const reportData = yield call(ApiService.deleteBillList, payload);
      console.log("reportData", reportData);

      if (reportData) {
        history.push("/app/business/accountsPayable/list");
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}
export function* billDetails() {
  yield takeEvery(BILL_DETAILS, function* ({ payload }) {
    console.log("in journal detail payload", payload);
    try {
      const reportData = yield call(ApiService.getBillDetails, payload);
      console.log("reportData", reportData);
      if (reportData) {
        yield put(billDetailsSuccess(reportData.data));
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* billStatusUpdate() {
  yield takeEvery(BILL_UPDATE_STATUS, function* ({ payload }) {
    console.log("in journal status payload", payload);
    try {
      const reportData = yield call(ApiService.updateBillStatus, payload);
      console.log("reportData", reportData);
      if (reportData) {
        const { id, billStatus, history } = payload;

        if (billStatus == "DRAFT") {
          history.push(`/app/business/accountsPayable/list${id}`);
        } else {
          history.push(`/app/business/accountsPayable/view?id=${id}`);
        }
      } else {
        yield put(showAuthMessage("Something went wrong"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(expense),
    fork(searchExpense),
    fork(deleteExpense),
    fork(addExpense),

    fork(quote),
    fork(searchquote),
    fork(deletequote),
    fork(addquote),
    fork(quoteDetails),
    fork(quoteStatusUpdate),
  ]);
}
