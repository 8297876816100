import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Inbox from './Inbox';
import Business from './Business';
import User from './User';


const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    inboxReducer: Inbox,
    business: Business,
    user:User,

});

export default reducers;