const dev = {
  API_ENDPOINT_URL: "https://api.speakho.co/api/v1",
  API_LINE_EXTRACTION_ENDPOINT_URL: "https://api.freco.binery.co",
  API_CONSUMER: "https://four.api.binery.co",
  API_KEY_CONSUMER: "Ip18rvOhmF7wjv7tFQ0TNewPo0yM2oMFRol2xuNptvU",
};

const prod = {
  API_ENDPOINT_URL: "https://api.speakho.co/api/v1",
  API_LINE_EXTRACTION_ENDPOINT_URL: "https://api.freco.binery.co",
  API_CONSUMER: "https://api.binery.co",
  API_KEY_CONSUMER: "Ip18rvOhmF7wjv7tFQ0TNewPo0yM2oMFRol2xuNptvU",
};

const test = {
  API_ENDPOINT_URL: "https://api.speakho.co/api/v1",
  API_LINE_EXTRACTION_ENDPOINT_URL: "https://api.freco.binery.co",
  API_CONSUMER: "https://four.api.binery.co2",
  API_KEY_CONSUMER: "Ip18rvOhmF7wjv7tFQ0TNewPo0yM2oMFRol2xuNptvU",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
