import {
	EXPENSE_LIST_SUCCESS,
	EXPENSE_ADD_SUCCESS,
	QUOTE_LIST_SUCCESS,
	QUOTE_DETAILS_SUCCESS,
	QUOTE_ADD_SUCCESS,
	QUOTE_HIDE_MESSAGE,
	QUOTE_CLEAR_DATA,
	JOURNAL_ADD_SUCCESS,
	JOURNAL_LIST_SUCCESS,
	JOURNAL_DETAILS_SUCCESS,
	JOURNAL_CLEAR_DATA,
	JOURNAL_HIDE_MESSAGE,

	INVOICE_ADD_SUCCESS,
	INVOICE_LIST_SUCCESS,
	INVOICE_DETAILS_SUCCESS,
	INVOICE_CLEAR_DATA,
	INVOICE_HIDE_MESSAGE,
	INVOICE_PAYMENT_SUCCESS,

	PURCHASE_ADD_SUCCESS,
	PURCHASE_LIST_SUCCESS,
	PURCHASE_DETAILS_SUCCESS,
	PURCHASE_CLEAR_DATA,
	PURCHASE_HIDE_MESSAGE,
	PURCHASE_PAYMENT_SUCCESS,

	BILL_ADD_SUCCESS,
	BILL_LIST_SUCCESS,
	BILL_DETAILS_SUCCESS,
	BILL_CLEAR_DATA,
	BILL_HIDE_MESSAGE,
	BILL_PAYMENT_SUCCESS,
	START_LOADING,
	MARK_AS_LOADING,

} from '../constants/App';
import moment from 'moment';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  expenseListData: [],

  quoteSuccess: false,
  quoteMessage: '',
  quoteListData: [],
  quoteDetailsData:{
	id: "",
    narration: "",
	txDate:'',
	txNumber:'',
	currencyId:'1',
	dueDate:'',
    taxIncluded: "TAX_INCLUDED",
    reference:"",
	title: "",
	summary: "",
	terms: "",
	subTotal: 0,
	totalTax: 0,
	tax:0,
	status: "CREATE",
	sent: "",
	amountPaid: 0,
	currencyId:"",
	contact: {
		id:'',
		name:'',
		email: '',
	},
	currency: {
		id:'',
		name:'',
	  },

  },
  journalSuccess: false,
  journalMessage: '',
  journalListData: [],
  journalDetailsData:{
	id: "",
    narration: "",
	journalDate:'',
	journalType: 'Manual',
    taxIncluded: "TAX_INCLUDED",
    reference:"",
    referenceId:"",
   
	journalStatus: "CREATE"
  },

  invoiceSuccess: false,
  invoiceMessage: '',
  invoiceListData: [],
  invoiceDetailsData:{
	id: "",
    narration: "",
	txDate:'',
	txNumber:'',
	currencyId:'1',
	dueDate:'',
	journalType: 'Manual',
    taxIncluded: "TAX_INCLUDED",
    reference:"",
    referenceId:"",
	subTotal: 0,
	totalTax: 0,
	tax:0,
	status: "CREATE",
	sent: "",
	amountPaid: 0,
	currencyId:"",
	contact: {
		id:'',
		name:'',
		email:'',
	},
  currency: {
    id:'',
    name:'',
  },
	payments:[]

  },
  invoicePaymentSuccess: false,


  purchaseSuccess: false,
  purchaseMessage: '',
  purchaseListData: [],
  purchaseDetailsData:{
	id: "",
    narration: "",
	txDate:'',
	txNumber:'',
	currencyId:'1',
	dueDate:'',
	journalType: 'Manual',
    taxIncluded: "TAX_INCLUDED",
    reference:"",
    referenceId:"",
	subTotal: 0,
	totalTax: 0,
	tax:0,
	status: "CREATE",
	sent: "",
	amountPaid: 0,
	currencyId:"",
	contact: {
		id:'',
		name:'',
		email:''
	},
	lines: [
		{
		
			tax: {
				"id": '',
				"taxRate": '',
				"name": ''
			},
			coa: {
				"id": '',
				"name": "",
				"code": ""
			}
		}
	],
  currency: {
    id:'',
    name:'',
  },

  },

  billSuccess: false,
  billMessage: '',
  billListData: [],
  billDetailsData:{
	id: "",
	txDate:'',
	currencyId:'1',
	dueDate:'',
    taxIncluded: "TAX_INCLUDED",
    reference:"",
    referenceId:"",
	subTotal: 0,
	totalTax: 0,
	total:0,
	status: "CREATE",
	amountPaid: 0,
	currencyId:"",
	contact: {
		id:'',
		name:'',
	},
	payments:[]

  },
  invoicePaymentSuccess: false,
  markAsLoading: false,

}

const business = (state = initState, action) => {
	switch (action.type) {
		case START_LOADING:
			return {
				...state,
				loading: true,
			}

		case EXPENSE_LIST_SUCCESS:
			console.log("in settings reducer",action);
			return {
				...state,
				loading: false,
				redirect: '/',
				expenseListData: action.payload.expenseData
			}

			case QUOTE_LIST_SUCCESS:
			console.log("in quote reducer",action);
			return {
				...state,
				loading: false,
				redirect: '/',
				quoteListData: action.payload.data.data
			}

			case QUOTE_DETAILS_SUCCESS:
			console.log("in quote reducer",action);
			return {
				...state,
				loading: false,
				markAsLoading:false,
				redirect: '/',
				quoteDetailsData: action.payload
			}

			case QUOTE_ADD_SUCCESS:
				console.log("in QUOTE ADD SUCCESS",action);
				return {
					...state,
					loading: false,
					quoteMessage:action.payload,
					quoteSuccess: true
				}

			case QUOTE_HIDE_MESSAGE:
				console.log("in QUOTE ADD SUCCESS",action);
				return {
					...state,
					loading: false,
					message:"",
					showMessage: false
				}

			case QUOTE_CLEAR_DATA:
				console.log("in QUOTE clear SUCCESS",action);
				return {
					...state,
					loading: true,
					quoteSuccess: false,
					quoteMessage: '',
					quoteListData: [],
					quoteDetailsData:{
					  id: "",
					  narration: "",
					  txDate:'',
					  txNumber:'',
					  currencyId:'1',
					  dueDate:'',
					  taxIncluded: "TAX_INCLUDED",
					  reference:"",
					  title: "",
					  summary: "",
					  terms: "",
					  subTotal: 0,
					  totalTax: 0,
					  tax:0,
					  status: "CREATE",
					  sent: "",
					  amountPaid: 0,
					  currencyId:"",
					  contact: {
						  id:'',
						  name:'',
						  email:''
					  },
					  currency: {
						id:'',
						name:'',
					  },
				  
					},
				}

			case JOURNAL_ADD_SUCCESS:
					console.log("in JOURNAL ADD SUCCESS",action);
					return {
						...state,
						loading: false,
						journalMessage:action.payload,
						journalSuccess: true
					}
			case JOURNAL_LIST_SUCCESS:
						console.log("in quote reducer",action);
						return {
							...state,
							loading: false,
							redirect: '/',
							journalListData: action.payload.journalData
							
						}

			case JOURNAL_DETAILS_SUCCESS:
							console.log("in journal reducer",action);
							return {
								...state,
								loading: false,
								redirect: '/',
								journalDetailsData: action.payload
							}
			case JOURNAL_CLEAR_DATA:
								console.log("in JOURNAL clear SUCCESS",action);
								return {
									...state,
									loading: false,
									message:"",
									showMessage: false,
									journalSuccess: false,
									journalDetailsData :{
										id: "",
										narration: "",
										journalDate:'',
										journalType: 'Manual',
										taxIncluded: "TAX_INCLUDED",
										reference:"",
										referenceId:"",
										journalStatus: "CREATE"
									  }
								}
			case JOURNAL_HIDE_MESSAGE:
									console.log("in JOURNAL HIDE MESSAGE",action);
									return {
										...state,
										loading: false,
										journalSuccess: false,
										journalMessage:"",

									}


			case INVOICE_ADD_SUCCESS:
					console.log("in INVOICE ADD SUCCESS",action);
					return {
						...state,
						loading: false,
						invoiceMessage:action.payload,
						invoiceSuccess: true
					}
			case INVOICE_LIST_SUCCESS:
						console.log("in invoice reducer",action);
						return {
							...state,
							loading: false,
							redirect: '/',
							invoiceListData: action.payload.invoiceData
							
						}

			case INVOICE_DETAILS_SUCCESS:
							console.log("in invoice reducer",action);
							let data = initState.invoiceDetailsData;
							if(action.payload.id != "")
							{
								data = action.payload
							}
							return {
								...state,
								loading: false,
								redirect: '/',
								invoiceDetailsData: data
							}
			case INVOICE_CLEAR_DATA:
								console.log("in INVOICE clear SUCCESS",action);
								return {
									...state,
									 loading: true,
									message:"",
									showMessage: false,
									invoiceSuccess: false,
									invoiceDetailsData : {
										id: "",
										narration: "",
										txDate:'',
										invoiceNumber:'',
										currencyId:'1',
										dueDate:'',
										journalType: 'Manual',
										taxIncluded: "TAX_INCLUDED",
										reference:"",
										referenceId:"",
										subTotal: 0,
										totalTax: 0,
										tax:0,
										status: "CREATE",
										sent: "",
										amountPaid: 0,
										currencyId:"",
										contact: {
											id:'',
											name:'',
											email:''
										},
										currency: {
											id:'',
											name:'',
										},
										payments:[]
									
									  }
								}
			case INVOICE_HIDE_MESSAGE:
									console.log("in INVOICE HIDE MESSAGE",action);
									return {
										...state,
										loading: false,
										invoiceSuccess: false,
										invoiceMessage:"",

									}
			case INVOICE_PAYMENT_SUCCESS:
				console.log("in invoice reducer",action);
				return {
					...state,
					loading: false,
					redirect: '/',
					invoiceSuccess: false,
					invoiceMessage: action.payload
				}

			case PURCHASE_ADD_SUCCESS:
					console.log("in PURCHASE ADD SUCCESS",action);
					return {
						...state,
						loading: false,
						purchaseMessage:action.payload,
						purchaseSuccess: true
					}
			case PURCHASE_LIST_SUCCESS:
						console.log("in purchase reducer",action);
						return {
							...state,
							loading: false,
							redirect: '/',
							purchaseListData: action.payload.purchaseData
							
						}

			case PURCHASE_DETAILS_SUCCESS:
							console.log("in purchase reducer",action);
							let data1 = initState.data;
							if(action.payload.id != "")
							{
								data1 = action.payload
							}
							return {
								...state,
								loading: false,
								redirect: '/',
								purchaseDetailsData: data1
							}
						
			case PURCHASE_CLEAR_DATA:
								console.log("in PURCHASE clear SUCCESS",action);
								return {
									...state,
									 loading: true,
									message:"",
									showMessage: false,
									purchaseSuccess: false,
									purchaseDetailsData:{
										id: "",
										narration: "",
										txDate:'',
										txNumber:'',
										currencyId:'1',
										dueDate:'',
										journalType: 'Manual',
										taxIncluded: "TAX_INCLUDED",
										reference:"",
										referenceId:"",
										subTotal: 0,
										totalTax: 0,
										tax:0,
										status: "CREATE",
										sent: "",
										amountPaid: 0,
										currencyId:"",
										contact: {
											id:'',
											name:'',
											email: '',
										},
										lines: [
											{
											
												tax: {
													"id": '',
													"taxRate": '',
													"name": ''
												},
												coa: {
													"id": '',
													"name": "",
													"code": ""
												}
											}
										],

										currency: {
											id:'',
											name:'',
										},
									
									  },
								}
			case PURCHASE_HIDE_MESSAGE:
									console.log("in PURCHASE HIDE MESSAGE",action);
									return {
										...state,
										loading: false,
										purchaseSuccess: false,
										purchaseMessage:"",

									}

				case BILL_ADD_SUCCESS:
					console.log("in BILL ADD SUCCESS",action);
					return {
						...state,
						loading: false,
						billMessage:action.payload,
						billSuccess: true
					}
			case BILL_LIST_SUCCESS:
						console.log("in bill reducer",action);
						return {
							...state,
							loading: false,
							redirect: '/',
							billListData: action.payload.data
							
						}

			case BILL_DETAILS_SUCCESS:
							console.log("in bill reducer",action);
							return {
								...state,
								loading: false,
								redirect: '/',
								billDetailsData: action.payload
							}
			case BILL_CLEAR_DATA:
								console.log("in BILL clear SUCCESS",action);
								return {
									...state,
									loading: true,
									message:"",
									showMessage: false,
									billSuccess: false,
									billMessage: '',
									billListData: [],
									billDetailsData:{
										id: "",
										txDate:'',
										currencyId:'1',
										dueDate:'',
										taxIncluded: "TAX_INCLUDED",
										reference:"",
										referenceId:"",
										subTotal: 0,
										totalTax: 0,
										total:0,
										status: "CREATE",
										amountPaid: 0,
										currencyId:"",
										contact: {
											id:'',
											name:'',
										},
										payments:[]

									},
								}
			case BILL_HIDE_MESSAGE:
									console.log("in BILL HIDE MESSAGE",action);
									return {
										...state,
										loading: false,
										billSuccess: false,
										billMessage:"",

									}
			case BILL_PAYMENT_SUCCESS:
				console.log("in bill reducer",action);
				return {
					...state,
					loading: false,
					redirect: '/',
					billSuccess: false,
					billMessage: action.payload
				}
			case MARK_AS_LOADING:
					return {
						...state,
						loading: false,
						markAsLoading:action.payload,
					}


		default:
			return state;
	}
}

export default business