import { useEffect, useState } from "react";
import { Menu, Dropdown, Avatar, notification,Tag } from "antd";
import { connect } from "react-redux";
import { LogoutOutlined, LockOutlined, TagOutlined } from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import authApi from "api/auth";
import { useHistory } from "react-router-dom";
import ChangePassword from "views/app-views/pages/setting/ChangePassword";

const menuItem = [
  // {
  // 	title: "Edit Profile",
  // 	icon: EditOutlined ,
  // 	path: "/"
  //   },
  //   {
  // 	title: "Account Setting",
  // 	icon: SettingOutlined,
  // 	path: "/"
  //   },
  //   {
  // 	title: "Billing",
  // 	icon: ShopOutlined ,
  // 	path: "/"
  // },
  //   {
  // 	title: "Help Center",
  // 	icon: QuestionCircleOutlined,
  // 	path: "/"
  // }
];
const openInNewTab = url => {
  window.open('https://www.speakho.co#comp-l9ckmk22', '_blank', 'noopener,noreferrer');
};


export const NavProfile = (props) => {
  const history = useHistory();
  const [userDetails, setUserDetails] = useState({
    "full_name": "speakho User",
    "email": "",
    "is_active": false,
    "is_superuser": false,
    "subscription_plan": {
      "id": 0,
      "plan_name": "",
      "amount": 0,
      "audio_duration": 0
    },
  });

  // const { userData } = props;
  // console.log('userData', userData);


  const getUserDetails = async () => {
    try {
      const data = await authApi.getMe();
      const {user} = data
      setUserDetails(user);
    } catch (error) {
      // notification.error({
      //   message: "something went wrong.",
        
      // });
      window.location.href = "/auth/login";
    }
  };

  const signOut = async () => {
    const { success } = await authApi.logout();
    if (success) {
      localStorage.clear();
      window.location.href = "/auth/login";
    }
  };

  const changePassword = () => {
    window.location.href = "/app/change-password";
  }

  useEffect(() => {
    getUserDetails();
  }, []);
  const profileImg = "/img/avatars/UserIcon.svg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{userDetails?.full_name}</h4>
            <span className="text-muted">{userDetails?.email}</span>
          </div>
        </div>
      
      </div>

      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 2} >
            <span>
              <TagOutlined className="mr-3" />
              <span className="font-weight-normal" ><>Plan: <strong>{userDetails.subscription_plan.plan_name.toUpperCase() }</strong></></span>
              {
                userDetails.subscription_plan.plan_name !== 'Advanced' &&
              <Tag color="#00c067" style={{ marginLeft: 10 }} onClick={() => { openInNewTab() }}>Upgrade</Tag>
            }
            </span>
          </Menu.Item>
          <Menu.Item key={menuItem.length + 3} onClick={(e) => changePassword()}>
            <span>
              <LockOutlined className="mr-3" />
              <span className="font-weight-normal">Change Password</span>
            </span>
          </Menu.Item>
          
          <Menu.Item key={menuItem.length + 1} onClick={(e) => signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};


// const mapStateToProps = ({ user }) => {
//   const { userData } = user;
//   // console.log('user',userData)

//   return { userData };
// };


export default connect(null, { signOut })(NavProfile);
