import React, { Component, useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Drawer, Menu, Button } from "antd";
import { connect } from "react-redux";
import { DIR_RTL } from "constants/ThemeConstant";
// import {useLocation} from "react-router-dom";

import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { getInbox } from "redux/actions/Auth";

const { Dragger } = Upload;

// const useQuery=()=> {
//   const { search } = useLocation();

//   return React.useMemo(() => new URLSearchParams(search), [search]);
//   }

export const NavPanel = (props) => {
  // const url = window.location.href.split('/', -1)
  // const pageName = url[url.length-2]
  // console.log('window.location.href',pageName);

  // // let query = useQuery();
  // console.log('props.currentRouteInfo', props.currentRouteInfo);

  const [visible, setVisible] = useState(false);

  const pageName = props.currentRouteInfo;

  let keyPage = "";
  if (pageName != undefined) {
    keyPage = pageName.key;
  }
  //state = { visible: false };

  const showDrawer = () => {
    // this.setState({
    //   visible: true,
    // });
    setVisible(true);
  };

  const onClose = () => {
    // this.setState({
    //   visible: false,
    // });
    setVisible(false);
  };

  const propsdata = {
    name: "file",
    accept: "application/pdf",
    multiple: true,
    showUploadList: {
      showPreviewIcon: true,
      showDownloadIcon: true,
      showRemoveIcon: false,
    },
    headers: {
      Authorization: "Bearer " + localStorage.getItem("auth_token"),
      Accept: "application/json, text/plain, */*",
      // "Content-Type": "multipart/form-data",
    },
    action: "https://api.freco.binery.co/po_files/uploadfile",
    method: "post",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        onClose();
        window.location.reload();
        // props.getInbox();
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      {/* <Menu mode="horizontal"> */}
      <div onClick={showDrawer}>
        {/* <Button
          style={{ background: "#EC5600", borderColor: "#EC5600" }}
          type="primary"
          icon={<PlusOutlined />}
          size={"small"}
         
        >
          Upload File
        </Button> */}
      </div>
      {/* </Menu> */}
      {/* <Drawer
        title="Upload File"
        placement={propsdata.direction === DIR_RTL ? "left" : "right"}
        width={window.innerWidth > 450 ? 450 : window.innerWidth}
        onClose={onClose}
        visible={visible}
      >
       
        <div style={{ height: "50%" }}>
          <Dragger {...propsdata}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Limit 5MB per file • MP3</p>
          </Dragger>
        </div>
      </Drawer> */}
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};
const mapDispatchToProps = {
  getInbox,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavPanel);
