import fetch from "auth/FetchInterceptor";
import axios from "axios";
import { API_BASE_URL, API_BASE_URL_NEW } from "configs/AppConfig";

const ApiService = {};

ApiService.getProfitLoss = function (params) {
  return fetch({
    url: "/api/report/profit-loss",
    method: "post",
    data: params,
  });
};
ApiService.getBalanceSheet = function (params) {
  return fetch({
    url: "/api/report/balance-sheet",
    method: "post",
    data: params,
  });
};
ApiService.getTrialBalance = function (params) {
  return fetch({
    url: "/api/report/trial-balance",
    method: "post",
    data: params,
  });
};
ApiService.getGeneralLedger = function (params) {
  // return axios.post(API_BASE_URL+'/api/generalLedger',params);
  return fetch({
    url: "/api/report/general-ledger",
    method: "post",
    data: params,
  });
};
ApiService.getAccountTransaction = function (params) {
  return fetch({
    url: "/api/report/account-transaction",
    method: "post",
    data: params,
  });
};

ApiService.getJournalReport = function (params) {
  // return axios.post(API_BASE_URL+'/api/generalLedger',params);
  return fetch({
    url: "/api/report/journal-report",
    method: "post",
    data: params,
  });
};

ApiService.getTaxList = function (params) {
  console.log("params", params);
  // return axios.post(API_BASE_URL+'/api/tax/list', params);
  return fetch({
    url: "/api/tax/list",
    method: "post",
    data: params,
  });
};

ApiService.searchTaxList = function (params) {
  console.log("params", params);
  return fetch({
    url: "/api/tax/list",
    method: "post",
    data: params,
  });
};

ApiService.deleteTaxList = function (params) {
  console.log("params", params);
  if (params.length) {
    if (params.length === 1) {
      return fetch({
        url: "/api/tax/" + params[0].id,
        method: "delete",
        data: params,
      });
    }
  }
  //return axios.post(API_BASE_URL+'/api/deleteTax',params);
};

ApiService.addTaxList = function (params) {
  console.log("params add tax list", params);
  // for add new tax
  if (!params.id) {
    var data = {
      name: params.name,
      taxRate: params.taxRate,
      type: "NONE",
      taxStatus: params.taxStatus,
    };
    //return axios.post(API_BASE_URL+'/api/addTax',params);
    return fetch({
      url: "/api/tax/create",
      method: "post",
      data: data,
    });
  } else {
    var data = {
      name: params.name,
      taxRate: params.taxRate,
      type: params.type,
      taxStatus: params.taxStatus,
    };
    //return axios.post(API_BASE_URL+'/api/addTax',params);
    return fetch({
      url: "/api/tax/" + params.id,
      method: "patch",
      data: data,
    });
  }
};

ApiService.getExpenseList = function (params) {
  console.log("params", params);
  // return axios.post(API_BASE_URL+'/api/tax/list', params);
  return fetch({
    url: "/api/expense/list",
    method: "post",
    data: params,
  });
};

ApiService.searchExpenseList = function (params) {
  return fetch({
    url: "/api/expense/list",
    method: "post",
    data: params,
  });
};

ApiService.deleteExpenseList = function (params) {
  console.log("params", params);
  if (params.length) {
    if (params.length === 1) {
      return fetch({
        url: "/api/expense/" + params[0].id,
        method: "delete",
        data: params,
      });
    }
  }
  //return axios.post(API_BASE_URL+'/api/deleteTax',params);
};

ApiService.addExpenseList = function (params) {
  console.log("params add expense list", params);
  // for add new expense
  var data = {
    description: params.description,
    reference: params.reference,
    spentAt: params.spentAt,
    spentOn: params.spentOn,
    amount: params.amount,
    status: params.status,
    currencyId: params.currencyId,
    coaId: params.coaId,
  };
  if (!params.id) {
    return fetch({
      url: "/api/expense/create",
      method: "post",
      data: data,
    });
  } else {
    return fetch({
      url: "/api/expense/" + params.id,
      method: "patch",
      data: data,
    });
  }
};

//dashboard

ApiService.getDashboardData = function (params) {
  //return axios.post(API_BASE_URL+'/api/contact',params);
  return fetch({
    url: "/dashboard/",
    method: "get",
    // data: params,
  });
};

ApiService.getDashboardChartData = function (params) {
  //return axios.post(API_BASE_URL+'/api/contact',params);
  return fetch({
    url: "/api/dashboard/getChartData",
    method: "get",
    data: params,
  });
};

ApiService.getDashboardCashInOutData = function (params) {
  //return axios.post(API_BASE_URL+'/api/contact',params);
  return fetch({
    url: "/api/dashboard/cashInCashOut",
    method: "post",
    data: params,
  });
};

//contact
ApiService.getContactList = function (params) {
  //return axios.post(API_BASE_URL+'/api/contact',params);
  return fetch({
    url: "/api/contact/list",
    method: "post",
    data: params,
  });
};

ApiService.deleteContactList = function (params) {
  console.log("params", params);

  return fetch({
    url: "/api/contact/" + params.id,
    method: "delete",
    data: params,
  });
};

ApiService.addContactList = function (params) {
  console.log("params add tax list", params);
  if (params.id == "" || params.id == "0") {
    return fetch({
      url: "/api/contact/create",
      method: "post",
      data: params,
    });
  } else {
    return fetch({
      url: "/api/contact/" + params.id,
      method: "patch",
      data: params,
    });
  }
};

ApiService.getContactDetails = function (params) {
  console.log("params in delete", params);

  return fetch({
    url: "/api/contact/" + params.id,
    method: "get",
    data: params,
  });
};

ApiService.getCompanyDetails = function (params) {
  console.log("params in company details", params);

  return fetch({
    url: "/api/company/details",
    method: "get",
    data: params,
  });
};

ApiService.updateCompanyDetails = function (params) {
  console.log("params in company details update", params);

  return fetch({
    url: "/api/company/" + params.id,
    method: "patch",
    data: params,
  });
};

//quote
ApiService.getQuoteList = function (params) {
  return axios.get(API_BASE_URL_NEW + "/bankstatements");
  // return fetch({
  //   url: '/bankstatements',
  //   method: 'get',
  //   data: params
  // })
};

ApiService.deleteQuoteList = function (params) {
  console.log("params", params);

  return fetch({
    url: "/api/quote/" + params.id,
    method: "delete",
    data: params,
  });
};

ApiService.deleteBillList = function (params) {
  console.log("params", params);

  return fetch({
    url: "/api/bill/" + params.id,
    method: "delete",
    data: params,
  });
};

ApiService.deletePurchaseList = function (params) {
  console.log("params", params);

  return fetch({
    url: "/api/purchase/" + params.id,
    method: "delete",
    data: params,
  });
};

ApiService.addQuoteList = function (params) {
  console.log("params add quote list", params);
  if (params.id == "0" || params.id == "" || params.id == "new") {
    return fetch({
      url: "/api/quote/create",
      method: "post",
      data: params,
    });
  } else {
    return fetch({
      url: "/api/quote/" + params.id,
      method: "patch",
      data: params,
    });
  }
};

ApiService.addJournalList = function (params) {
  console.log("params add journal list", params);
  if (params.id == "0" || params.id == "") {
    return fetch({
      url: "/api/journal/create",
      method: "post",
      data: params,
    });
  } else {
    return fetch({
      url: "/api/journal/" + params.id,
      method: "patch",
      data: params,
    });
  }
};

ApiService.getJournalList = function (params) {
  //return axios.post(API_BASE_URL+'/api/contact',params);
  return fetch({
    url: "/api/journal/list",
    method: "post",
    data: params,
  });
};

ApiService.getJournalDetails = function (params) {
  console.log("params in get", params);

  return fetch({
    url: "/api/journal/" + params.id,
    method: "get",
    data: params,
  });
};

ApiService.updateJournalStatus = function (params) {
  console.log("params update journal status", params);
  return fetch({
    url: "/api/journal/" + params.id,
    method: "post",
    data: params,
  });
};

ApiService.deleteJournalList = function (params) {
  console.log("params", params);

  return fetch({
    url: "/api/journal/" + params.id,
    method: "delete",
    data: params,
  });
};

ApiService.addInvoiceList = function (params) {
  console.log("params add invoice list", params);
  if (params.id == "0" || params.id == "" || params.id == "new") {
    return fetch({
      url: "/api/invoice/create",
      method: "post",
      data: params,
    });
  } else {
    return fetch({
      url: "/api/invoice/" + params.id,
      method: "patch",
      data: params,
    });
  }
};

ApiService.getInvoiceList = function (params) {
  //return axios.post(API_BASE_URL+'/api/contact',params);
  return fetch({
    url: "/api/invoice/list",
    method: "post",
    data: params,
  });
};

ApiService.getInvoiceDetails = function (params) {
  console.log("params in get", params);

  return fetch({
    url: "/api/invoice/" + params.id,
    method: "get",
    data: params,
  });
};

ApiService.updateInvoiceStatus = function (params) {
  console.log("params update invoice status", params);
  return fetch({
    url: "/api/invoice/" + params.id,
    method: "post",
    data: params,
  });
};

ApiService.makeInvoicePayment = function (params) {
  console.log("params invoice payment", params);
  return fetch({
    url: "/api/invoice-payment",
    method: "post",
    data: params,
  });
};

ApiService.addPurchaseList = function (params) {
  console.log("params add purchase list", params);
  if (params.id == "0" || params.id == "" || params.id == "new") {
    return fetch({
      url: "/api/purchase/create",
      method: "post",
      data: params,
    });
  } else {
    return fetch({
      url: "/api/purchase/" + params.id,
      method: "patch",
      data: params,
    });
  }
};

ApiService.getPurchaseList = function (params) {
  //return axios.post(API_BASE_URL+'/api/contact',params);
  return fetch({
    url: "/api/purchase/list",
    method: "post",
    data: params,
  });
};

ApiService.getPurchaseDetails = function (params) {
  console.log("params in get", params);

  return fetch({
    url: "/api/purchase/" + params.id,
    method: "get",
    data: params,
  });
};

ApiService.updatePurchaseStatus = function (params) {
  console.log("params update purchase status", params);
  return fetch({
    url: "/api/purchase/" + params.id,
    method: "post",
    data: params,
  });
};

ApiService.addBillList = function (params) {
  console.log("params add bill list", params);
  if (params.id == "0" || params.id == "" || params.id == "new") {
    return fetch({
      url: "/api/bill/create",
      method: "post",
      data: params,
    });
  } else {
    return fetch({
      url: "/api/bill/" + params.id,
      method: "patch",
      data: params,
    });
  }
};

ApiService.getBillList = function (params) {
  //return axios.post(API_BASE_URL+'/api/contact',params);
  return fetch({
    url: "/api/bill/list",
    method: "post",
    data: params,
  });
};

ApiService.getBillDetails = function (params) {
  console.log("params in get", params);

  return fetch({
    url: "/api/bill/" + params.id,
    method: "get",
    data: params,
  });
};

ApiService.updateBillStatus = function (params) {
  console.log("params update bill status", params);
  return fetch({
    url: "/api/bill/" + params.id,
    method: "post",
    data: params,
  });
};

ApiService.makeBillPayment = function (params) {
  console.log("params bill payment", params);
  return fetch({
    url: "/api/payment",
    method: "post",
    data: params,
  });
};

ApiService.updateQuoteStatus = function (params) {
  console.log("params update quote status", params);
  return fetch({
    url: "/api/quote/" + params.id,
    method: "post",
    data: params,
  });
};

ApiService.getQuoteDetails = function (params) {
  console.log("params in get", params);

  return fetch({
    url: "/api/quote/" + params.id,
    method: "get",
    data: params,
  });
};

//----------coa
ApiService.getCoaList = function (params) {
  console.log("params", params);
  // return axios.post(API_BASE_URL+'/api/coa',params);
  // let type = ""
  // if(params == 2)
  // {
  //   type = "ASSETS"
  // }
  // else if(params == 3)
  // {
  //   type = "LIABLITIES"
  // }
  // else if(params == 4)
  // {
  //   type = "EQUITY"
  // }
  // else if(params == 5)
  // {
  //   type = "EXPENSE"
  // }
  // else if(params == 6)
  // {
  //   type = "REVENUE"
  // }
  // else if(params == 1)
  // {
  //   type = ""
  // }
  const data = {
    page: 1,
    searchText: "",
    type: params,
  };
  return fetch({
    url: "/api/coa/list",
    method: "post",
    data: data,
  });
};

ApiService.getCoaExpenseList = function (params) {
  console.log("params", params);

  return fetch({
    url: "/api/coa/list-coa-expense",
    method: "get",
    data: {},
  });
};

ApiService.getCoaBankList = function (params) {
  console.log("params", params);

  return fetch({
    url: "/api/coa/list-coa-bank",
    method: "get",
    data: {},
  });
};

ApiService.searchCoaList = function (params) {
  console.log("params", params);
  return fetch({
    url: "/api/coa/list",
    method: "post",
    data: params,
  });
};

ApiService.deleteCoaList = function (params) {
  console.log("params in delete", params);

  return fetch({
    url: "/api/coa/" + params.id,
    method: "delete",
    data: params,
  });
};

ApiService.addCoaList = function (params) {
  console.log("params add tax list", params);
  if (!params.id) {
    return fetch({
      url: "/api/coa/create",
      method: "post",
      data: params,
    });
  } else {
    return fetch({
      url: "/api/coa/" + params.id,
      method: "patch",
      data: params,
    });
  }
};

ApiService.addCoaBankList = function (params) {
  console.log("params add bank list", params);
  if (!params.id) {
    return fetch({
      url: "/api/coa/createBank",
      method: "post",
      data: params,
    });
  } else {
    return fetch({
      url: "/api/coa/bank/" + params.id,
      method: "patch",
      data: params,
    });
  }
};

ApiService.getInvoiceNumber = function (params) {
  return fetch({
    url: "/api/invoice-number",
    method: "get",
    data: params,
  });
};

ApiService.getPurchaseNumber = function (params) {
  return fetch({
    url: "/api/purchase-number",
    method: "get",
    data: params,
  });
};

ApiService.getQuoteNumber = function (params) {
  return fetch({
    url: "/api/quote-number",
    method: "get",
    data: params,
  });
};

ApiService.getBankList = function (params) {
  return axios.get(API_BASE_URL + "/api/bank");
};

ApiService.getPost = function (params) {
  return fetch({
    url: "/posts/1",
    method: "get",
    params,
  });
};

ApiService.setPost = function (data) {
  return fetch({
    url: "/posts",
    method: "post",
    data: data,
  });
};

ApiService.postSendEmail = function (params) {
  return fetch({
    url: "/api/setting/send-email",
    method: "post",
    data: params,
  });
};

ApiService.login = async function (params) {
  console.log("params", params);
  return axios.post(API_BASE_URL + "/api/user/login", params);
  // return fetch({
  //   url: '/api/user/login',
  //   method: 'post',
  //   data: params
  // })
};

export default ApiService;
