import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { message } from "antd";
import { DEFAULT_ASSESSMENT_TYPE } from "constants/AppConstant";
import { API_BASE_URL } from "configs/AppConfig";
import authApi from "api/auth";

export const validateCustomer = async (searchParams) => {
  try {
    const accessKey = searchParams.get("access_key");
    const email = searchParams.get("email");
    const fullName = searchParams.get("full_name");
    const nativeCountry = searchParams.get("native_country");
    const nativeLanguage = searchParams.get("native_language");
    let assessmentType = searchParams.get("assessment_type");

    // Set default value for assessmentType if not provided
    assessmentType = assessmentType || DEFAULT_ASSESSMENT_TYPE;

    if (!accessKey || !email || !fullName) {
      throw new Error("Error: Access key, email, or full name not found.");
    }

    // Construct URL with all parameters
    let url = `${API_BASE_URL}/auth/validateCustomer?access_key=${accessKey}&email=${email}&full_name=${fullName}`;

    // Append optional parameters if available
    if (nativeCountry) {
      url += `&native_country=${nativeCountry}`;
    }
    if (nativeLanguage) {
      url += `&native_language=${nativeLanguage}`;
    }
    if (assessmentType) {
      url += `&assessment_type=${assessmentType}`;
    }

    const response = await axios.post(url);
    localStorage.setItem("auth_token", response.data.access_token);

    const userData = await authApi.getMe();
    const { user, settings } = userData;

    user.nativeCountry = nativeCountry || "";
    user.nativeLanguage = nativeLanguage || "";

    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("settings", JSON.stringify(settings));
    localStorage.setItem("customer_key", accessKey);
    return true;
  } catch (error) {
    console.error("Error:", error);
    throw new Error("Error occurred. Please contact your administrator.");
  }
};

const ValidateCustomerTrigger = () => {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get URL search parameters
        const searchParams = new URLSearchParams(location.search);
        // Validate customer data
        await validateCustomer(searchParams);
        // Redirect to assessment dashboard on successful validation
        window.location.href = "/app/dashboards/assessment";
      } catch (error) {
        // Set error message if validation fails
        setErrorMessage(error.message);
      }
    };

    // Fetch data when location.search changes
    fetchData();
  }, [location.search]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "22px",
        color: "red",
        textAlign: "center",
      }}
    >
      <div>{errorMessage && <div>{errorMessage}</div>}</div>
    </div>
  );
};

export default ValidateCustomerTrigger;
