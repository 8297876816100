import {
  EXPENSE_DELETE,
  EXPENSE_LIST,
  EXPENSE_LIST_SUCCESS,
  EXPENSE_SEARCH,
  EXPENSE_ADD,
  EXPENSE_ADD_SUCCESS,
  QUOTE_LIST,
  QUOTE_SEARCH,
  QUOTE_LIST_SUCCESS,
  QUOTE_DELETE,
  QUOTE_ADD,
  QUOTE_HIDE_MESSAGE,
  QUOTE_ADD_SUCCESS,
  QUOTE_DETAILS,
  QUOTE_DETAILS_SUCCESS,
  QUOTE_CLEAR_DATA,
  QUOTE_UPDATE_STATUS,
  JOURNAL_ADD,
  JOURNAL_ADD_SUCCESS,
  JOURNAL_LIST,
  JOURNAL_LIST_SUCCESS,
  JOURNAL_DETAILS,
  JOURNAL_DETAILS_SUCCESS,
  JOURNAL_DELETE,
  JOURNAL_CLEAR_DATA,
  JOURNAL_UPDATE_STATUS,
  JOURNAL_HIDE_MESSAGE,
  INVOICE_ADD,
  INVOICE_ADD_SUCCESS,
  INVOICE_LIST,
  INVOICE_LIST_SUCCESS,
  INVOICE_DETAILS,
  INVOICE_DETAILS_SUCCESS,
  INVOICE_CLEAR_DATA,
  INVOICE_UPDATE_STATUS,
  INVOICE_HIDE_MESSAGE,
  INVOICE_PAYMENT,
  INVOICE_PAYMENT_SUCCESS,
  PURCHASE_ADD,
  PURCHASE_ADD_SUCCESS,
  PURCHASE_LIST,
  PURCHASE_DELETE,
  PURCHASE_LIST_SUCCESS,
  PURCHASE_DETAILS,
  PURCHASE_DETAILS_SUCCESS,
  PURCHASE_CLEAR_DATA,
  PURCHASE_UPDATE_STATUS,
  PURCHASE_HIDE_MESSAGE,
  BILL_ADD,
  BILL_ADD_SUCCESS,
  BILL_LIST,
  BILL_LIST_SUCCESS,
  BILL_DETAILS,
  BILL_DELETE,
  BILL_DETAILS_SUCCESS,
  BILL_CLEAR_DATA,
  BILL_UPDATE_STATUS,
  BILL_HIDE_MESSAGE,
  BILL_PAYMENT,
  BILL_PAYMENT_SUCCESS,
  START_LOADING,
  MARK_AS_LOADING,
} from "../constants/App";

export const startLoading = (data) => {
  return {
    type: START_LOADING,
    payload: data,
  };
};

export const expenseList = (data) => {
  console.log("here in action");
  return {
    type: EXPENSE_LIST,
    payload: data,
  };
};

export const expenseSearch = (data) => {
  console.log("in search", data);
  return {
    type: EXPENSE_SEARCH,
    payload: data,
  };
};

export const expenseListSuccess = (data) => {
  return {
    type: EXPENSE_LIST_SUCCESS,
    payload: data,
  };
};

export const expenseDelete = (data) => {
  return {
    type: EXPENSE_DELETE,
    payload: data,
  };
};

export const expenseAdd = (data) => {
  return {
    type: EXPENSE_ADD,
    payload: data,
  };
};

export const expenseSuccess = (data) => {
  return {
    type: EXPENSE_ADD_SUCCESS,
    payload: data,
  };
};

export const quoteList = (data) => {
  console.log("here in quote");
  return {
    type: QUOTE_LIST,
    payload: data,
  };
};

export const quoteSearch = (data) => {
  return {
    type: QUOTE_SEARCH,
    payload: data,
  };
};

export const quoteListSuccess = (data) => {
  console.log("in action", data);
  return {
    type: QUOTE_LIST_SUCCESS,
    payload: data,
  };
};

export const quoteDelete = (data) => {
  return {
    type: QUOTE_DELETE,
    payload: data,
  };
};

export const quoteAdd = (data) => {
  return {
    type: QUOTE_ADD,
    payload: data,
  };
};

export const quoteHideMessage = (data) => {
  return {
    type: QUOTE_HIDE_MESSAGE,
    payload: data,
  };
};

export const quoteAddSuccess = (data) => {
  return {
    type: QUOTE_ADD_SUCCESS,
    payload: data,
  };
};

export const quoteDetails = (data) => {
  return {
    type: QUOTE_DETAILS,
    payload: data,
  };
};

export const quoteDetailsSuccess = (data) => {
  return {
    type: QUOTE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const quoteClearDetails = (data) => {
  return {
    type: QUOTE_CLEAR_DATA,
    payload: data,
  };
};

export const quoteUpdateStatus = (data) => {
  return {
    type: QUOTE_UPDATE_STATUS,
    payload: data,
  };
};

export const journalAdd = (data) => {
  console.log("hello journal");
  return {
    type: JOURNAL_ADD,
    payload: data,
  };
};

export const journalDelete = (data) => {
  return {
    type: JOURNAL_DELETE,
    payload: data,
  };
};

export const journalAddSuccess = (data) => {
  return {
    type: JOURNAL_ADD_SUCCESS,
    payload: data,
  };
};

export const journalList = (data) => {
  console.log("here in quote");
  return {
    type: JOURNAL_LIST,
    payload: data,
  };
};

export const journalListSuccess = (data) => {
  return {
    type: JOURNAL_LIST_SUCCESS,
    payload: data,
  };
};

export const journalDetails = (data) => {
  return {
    type: JOURNAL_DETAILS,
    payload: data,
  };
};

export const journalDetailsSuccess = (data) => {
  return {
    type: JOURNAL_DETAILS_SUCCESS,
    payload: data,
  };
};

export const journalClearDetails = (data) => {
  return {
    type: JOURNAL_CLEAR_DATA,
    payload: data,
  };
};

export const journalUpdateStatus = (data) => {
  return {
    type: JOURNAL_UPDATE_STATUS,
    payload: data,
  };
};

export const journalHideMessage = (data) => {
  return {
    type: JOURNAL_HIDE_MESSAGE,
    payload: data,
  };
};

export const invoiceAdd = (data) => {
  console.log("hello journal");
  return {
    type: INVOICE_ADD,
    payload: data,
  };
};

export const invoiceAddSuccess = (data) => {
  return {
    type: INVOICE_ADD_SUCCESS,
    payload: data,
  };
};

export const invoiceList = (data) => {
  console.log("here in quote");
  return {
    type: INVOICE_LIST,
    payload: data,
  };
};

export const invoiceListSuccess = (data) => {
  return {
    type: INVOICE_LIST_SUCCESS,
    payload: data,
  };
};

export const invoiceDetails = (data) => {
  startLoading(data);
  return {
    type: INVOICE_DETAILS,
    payload: data,
  };
};

export const invoiceDetailsSuccess = (data) => {
  return {
    type: INVOICE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const invoiceClearDetails = (data) => {
  return {
    type: INVOICE_CLEAR_DATA,
    payload: data,
  };
};

export const invoiceUpdateStatus = (data) => {
  return {
    type: INVOICE_UPDATE_STATUS,
    payload: data,
  };
};

export const invoiceHideMessage = (data) => {
  return {
    type: INVOICE_HIDE_MESSAGE,
    payload: data,
  };
};

export const invoicePayment = (data) => {
  return {
    type: INVOICE_PAYMENT,
    payload: data,
  };
};

export const invoicePaymentSuccess = (data) => {
  return {
    type: INVOICE_PAYMENT_SUCCESS,
    payload: data,
  };
};

export const billAdd = (data) => {
  return {
    type: BILL_ADD,
    payload: data,
  };
};

export const billAddSuccess = (data) => {
  return {
    type: BILL_ADD_SUCCESS,
    payload: data,
  };
};

export const billDelete = (data) => {
  return {
    type: BILL_DELETE,
    payload: data,
  };
};

export const billList = (data) => {
  return {
    type: BILL_LIST,
    payload: data,
  };
};

export const billListSuccess = (data) => {
  return {
    type: BILL_LIST_SUCCESS,
    payload: data,
  };
};
export const billDetails = (data) => {
  return {
    type: BILL_DETAILS,
    payload: data,
  };
};

export const billDetailsSuccess = (data) => {
  return {
    type: BILL_DETAILS_SUCCESS,
    payload: data,
  };
};

export const billClearDetails = (data) => {
  return {
    type: BILL_CLEAR_DATA,
    payload: data,
  };
};

export const billUpdateStatus = (data) => {
  return {
    type: BILL_UPDATE_STATUS,
    payload: data,
  };
};

export const billHideMessage = (data) => {
  return {
    type: BILL_HIDE_MESSAGE,
    payload: data,
  };
};

export const billPayment = (data) => {
  return {
    type: BILL_PAYMENT,
    payload: data,
  };
};

export const billPaymentSuccess = (data) => {
  return {
    type: BILL_PAYMENT_SUCCESS,
    payload: data,
  };
};

export const purchaseAdd = (data) => {
  console.log("hello journal");
  return {
    type: PURCHASE_ADD,
    payload: data,
  };
};

export const purchaseAddSuccess = (data) => {
  return {
    type: PURCHASE_ADD_SUCCESS,
    payload: data,
  };
};

export const purchaseList = (data) => {
  console.log("here in quote");
  return {
    type: PURCHASE_LIST,
    payload: data,
  };
};

export const purchaseListSuccess = (data) => {
  return {
    type: PURCHASE_LIST_SUCCESS,
    payload: data,
  };
};

export const purchaseDetails = (data) => {
  startLoading(data);
  return {
    type: PURCHASE_DETAILS,
    payload: data,
  };
};

export const purchaseDetailsSuccess = (data) => {
  return {
    type: PURCHASE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const purchaseClearDetails = (data) => {
  return {
    type: PURCHASE_CLEAR_DATA,
    payload: data,
  };
};

export const purchaseUpdateStatus = (data) => {
  return {
    type: PURCHASE_UPDATE_STATUS,
    payload: data,
  };
};

export const purchaseHideMessage = (data) => {
  return {
    type: PURCHASE_HIDE_MESSAGE,
    payload: data,
  };
};

export const purchaseDelete = (data) => {
  return {
    type: PURCHASE_DELETE,
    payload: data,
  };
};

export const markLoading = (data) => {
  return {
    type: MARK_AS_LOADING,
    payload: data,
  };
};
