import fetch from 'auth/FetchInterceptor'

const crudservice = {}

crudservice.getinbox = function (params) {
  // return fetch({
  //   url: 'http://103.225.11.44:8000/api/ocr_requests',
  //   method: 'get',
  //   params
  // })
  return fetch("http://103.225.11.44:8000/api/ocr_requests")
}


export default crudservice