import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig';

export const APP_NAME = 'Speakho';

// export const API_BASE_URL = 'http://speakho.localhost/api/v1';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const API_CONSUMER_BASE_URL = env.API_CONSUMER;
export const API_BASE_URL_NEW = env.API_LINE_EXTRACTION_ENDPOINT_URL;
export const API_KEY_CONSUMER = env.API_KEY_CONSUMER;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const RESET_PREFIX_PATH = '/';

export const THEME_CONFIG = {
  navCollapsed: true,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'light',
  direction: DIR_LTR,
};
